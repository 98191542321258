import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './App';
import { setCanvasImg } from './Editor';
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './Vitals';

// Google Analytics Configuration
const GA_TRACKING_ID = 'G-E1V8NGJR2K'; // 请确保这是您的正确跟踪ID

const initGA = () => {
  if (typeof window.gtag === 'function') {
    return;
  }
  
  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
  script.async = true;
  document.head.appendChild(script);

  window.dataLayer = window.dataLayer || [];
  function gtag(...args) {
    window.dataLayer.push(args);
  }
  gtag('js', new Date());
  gtag('config', GA_TRACKING_ID);

  window.gtag = gtag;
};

const logPageView = (path) => {
  if (typeof window.gtag === 'function') {
    window.gtag('config', GA_TRACKING_ID, {
      page_path: path,
    });
  }
};

const logEvent = (action, category, label, value) => {
  if (typeof window.gtag === 'function') {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  }
};

// 初始化 Google Analytics
initGA();

export var image = new Image();
export var imageBase64;

export function setImage(base64) {
  console.log("img has been set. ")
  image.src = base64;
  image.onload = function () {
    if (image.width < 16 || image.height < 32) {
      alert("The image you uploaded is too small!");
      return;
    }
    document.getElementById('start').style.display = "none";
    document.getElementById('finish').style.display = "none";
    imageBase64 = image.src;
    if (image.width === 16 && image.height === 32) {
      document.getElementById('finish').style.display = "block";
      document.getElementById('finish-img').src = image.src;
    } else {
      document.getElementById('editor').style.display = "grid";
      setCanvasImg();
    }
    logEvent('image_set', 'user_action', 'image_dimensions', `${image.width}x${image.height}`);
  };
}

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0)
      return c.substring(name.length, c.length);
  }
  return "";
}

console.log("pngskin (https://pngskin.com/)")

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
  () => {
    logPageView(window.location.pathname);
  }
);

reportWebVitals(sendToVercelAnalytics);
